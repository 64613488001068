import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2c7ce2b4 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _65799237 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _06a87cac = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _e250fc9a = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _144a6c54 = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _7423c9b8 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _53c09ca4 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _8d901312 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _3c29ae00 = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _c8134220 = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _a14a6568 = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _3c416685 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _7359367e = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _7a3a0f01 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _dcb21b8e = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _d1d942da = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _46966b6c = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _7be0f64f = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _90ad74dc = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _19983f38 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _c2f45228 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _70b96769 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _057e6ac6 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _26357b4a = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _0652e785 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _494e7482 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _ec9f3fd6 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _c2f5c79a = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _fff92a5e = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _2429aac5 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _066ead0f = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _9337495e = () => interopDefault(import('../pages/wishlist/index.vue' /* webpackChunkName: "pages/wishlist/index" */))
const _7cdd2653 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _a19dc9e8 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _6f439940 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _cc93a38e = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _9d3cd8a2 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _76337d60 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _2025a8aa = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _de42ceae = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _94d83416 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _7e052483 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _41f6a26c = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _513436a2 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _575617c6 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _1995046c = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _4cdba3c4 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _05def699 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0e1aaf26 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _2c7ce2b4,
    children: [{
      path: "",
      component: _65799237,
      name: "account___nl"
    }, {
      path: "details",
      component: _06a87cac,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _e250fc9a,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _144a6c54,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _7423c9b8,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _53c09ca4,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _8d901312,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _3c29ae00,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _c8134220,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _a14a6568,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _3c416685,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _7359367e,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _7a3a0f01,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _dcb21b8e,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _d1d942da,
    name: "product-lister-actie___nl"
  }, {
    path: "/cart",
    component: _46966b6c,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _7be0f64f,
    children: [{
      path: "",
      component: _90ad74dc,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _19983f38,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _c2f45228,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _70b96769,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _057e6ac6,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _26357b4a,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _0652e785,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _494e7482,
    name: "icon___nl"
  }, {
    path: "/inspiratie",
    component: _ec9f3fd6,
    name: "blog-category___nl"
  }, {
    path: "/login",
    component: _c2f5c79a,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _fff92a5e,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _2429aac5,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _066ead0f,
    name: "forgot-password___nl"
  }, {
    path: "/wishlist",
    component: _9337495e,
    name: "wishlist___nl"
  }, {
    path: "/blocks/demo",
    component: _7cdd2653,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _a19dc9e8,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _6f439940,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _cc93a38e,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _9d3cd8a2,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/inspiratie/post/:slug?",
    component: _76337d60,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _2025a8aa,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _de42ceae,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _94d83416,
    name: "product-detail-slug___nl"
  }, {
    path: "/vestigingen/:slug?",
    component: _7e052483,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _41f6a26c,
    name: "search-slug___nl"
  }, {
    path: "/inspiratie/*",
    component: _513436a2,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _575617c6,
    name: "blog-tag-all___nl"
  }, {
    path: "/m/*",
    component: _1995046c,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _4cdba3c4,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _05def699,
    name: "index___nl"
  }, {
    path: "/*",
    component: _0e1aaf26,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
